/**
 * Sets a cookie if the value is not `undefined`. If the `value` is undefined, deletes the cookie.
 *
 * Works only in the browser environment.
 */
export function setCookieValue(name: string, value: unknown | undefined) {
  if (value) {
    document.cookie = `${name}=${String(value)}; SameSite=strict`

    return
  }

  document.cookie = `${name}=`
}

/**
 * Parses the cookie and return the value for a given cookie name.
 */
export function getCookieValue(cookie: string, name: string) {
  return cookie
    .split('; ')
    .find(item => item.startsWith(`${name}=`))
    ?.split('=')[1]
}
