import { IArtwork, TradePriceInfo } from '@/types'
import {
  FilterArtworkHitProps,
  ImageSizes,
  SavedArtwork,
  StrapiPagination,
} from 'types'
import { API_BASEPATH, Err, getApiBasepath } from '.'

export const artworkCardTradeFields = [
  'price',
  'currency',
  'priceGbp',
  'priceConvertedToGbp',
  'priceConvertedToUsd',
]

export const artworkCardPopulation = {
  fields: ['name'],
  populate: {
    artists: {
      fields: ['name'],
    },
    images: {
      fields: ['name', 'formats', 'url'],
    },
    editions: {
      fields: ['name'],
      populate: {
        lowestAsk: {
          fields: artworkCardTradeFields,
        },
        highestBid: {
          fields: artworkCardTradeFields,
        },
      },
    },
  },
}

export const artworkPagePopulation = {
  fields: [
    'id',
    'name',
    'medium',
    'releaseDate',
    'isFullDate',
    'royalty',
    'spreadPercentage',
    'upcomingRelease',
  ],
  populate: {
    gallery: {
      fields: ['name'],
    },
    images: {
      fields: ['name', 'formats', 'url'],
    },
    editions: {
      fields: [
        'name',
        'otherName',
        'material',
        'editionSize',
        'mainPrice',
        'currency',
        'information',
        'shipmentCategory',
        'createdAt',
      ],
      populate: {
        asks: {
          fields: [
            'status',
            'price',
            'priceGbp',
            'priceConvertedToGbp',
            'priceConvertedToUsd',
            'currency',
          ],
        },
        bids: {
          fields: [
            'status',
            'price',
            'priceGbp',
            'priceConvertedToGbp',
            'priceConvertedToUsd',
            'currency',
          ],
        },
        highestBid: {
          fields: artworkCardTradeFields,
        },
        lowestAsk: {
          fields: artworkCardTradeFields,
        },
        size: {
          fields: '*',
        },
        includes: {
          fields: '*',
        },
        features: {
          fields: '*',
        },
        additionalCertification: {
          fields: '*',
        },
      },
    },
    artists: {
      fields: ['name'],
      populate: {
        nationalities: {
          fields: ['name', 'alpha2Code'],
        },
      },
    },
  },
}

type SimilarArtworksResponse = {
  data: FilterArtworkHitProps[] | null
  err?: string
}

type Response = {
  data: IArtwork[]
  meta: {
    pagination: StrapiPagination
  }
}

type SaveArtworkRes = {
  id: number
  error?: Err
}

type GetSavedArtworksRes = {
  results: {
    id: number
    artwork: SavedArtwork
  }[]
  pagination: StrapiPagination
}

type IsArtworkSavedRes = {
  error?: Err
  id?: number
}

type GetBooleanValueRes = {
  data?: boolean
  error?: Err
}

export const getArtworks = async (query?: string) => {
  const response = await fetch(`${getApiBasepath()}/api/artworks?${query}`)
  const data = await response.json()
  return data as Response
}

export const getSimilarArtworks = async (artworkId: number) => {
  const response = await fetch(
    `${getApiBasepath()}/api/artworks/similar/${artworkId}`
  )
  const data = await response.json()
  return data as SimilarArtworksResponse
}

export const getArtwork = async (id?: number, query?: string) => {
  const response = await fetch(
    `${getApiBasepath()}/api/artworks/${id}?${query}`
  )
  const data = await response.json()
  return data as { data: IArtwork }
}

export const getTrendingArtworks = async () => {
  const response = await fetch(
    `${getApiBasepath()}/api/artworks/features/trending`
  )
  const data = await response.json()

  return data as {
    id: number
    artists: { id: number; name: string }[]
    editions: {
      id: number
      name: string
      highestBid?: { id?: number } & TradePriceInfo
      lowestAsk?: { id?: number } & TradePriceInfo
    }[]
    name: string
    images: ImageSizes[]
  }[]
}

export const saveArtwork = async (artworkId: number) => {
  const res = await fetch(`${API_BASEPATH}/api/save-artworks`, {
    method: 'POST',
    body: JSON.stringify({ artworkId }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
  return (await res.json()) as SaveArtworkRes
}

export const getSavedArtworks = async (
  payload: {
    name?: string
    pageSize?: string
    pageNumber?: string
    sortBy?: string
  } = {}
) => {
  const query = new URLSearchParams(payload).toString()

  const res = await fetch(`${getApiBasepath()}/api/save-artworks?${query}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
  const data = await res.json()
  return data as GetSavedArtworksRes
}

export const unsaveArtwork = async (id: number) => {
  const res = await fetch(`${API_BASEPATH}/api/save-artworks/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
  return (await res.json()) as { error?: Err }
}

export const isArtworkSaved = async (artworkId: number) => {
  const res = await fetch(
    `${API_BASEPATH}/api/artworks/is-saved/${artworkId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }
  )
  return (await res.json()) as IsArtworkSavedRes
}

export const getHasSales = async (artworkId: number) => {
  const res = await fetch(`${API_BASEPATH}/api/artworks/has-sales/${artworkId}`)
  const data = await res.json()
  return data as GetBooleanValueRes
}

export const getWasSoldRecently = async (artworkId: number) => {
  const res = await fetch(
    `${API_BASEPATH}/api/artworks/was-sold-recently/${artworkId}`
  )
  const data = await res.json()
  return data as GetBooleanValueRes
}

export const getIsTopViewed = async (artworkId: number) => {
  const res = await fetch(
    `${API_BASEPATH}/api/artworks/is-top-viewed/${artworkId}`
  )
  const data = await res.json()
  return data as GetBooleanValueRes
}

export type SubmitWorkPayload = {
  email: string
  artistName: string
  artworkName: string
  images: File[]
  releaseDate?: string
  gallery?: string
  size?: string
  editionSize?: string
  medium?: string
  material?: string
}

type SubmitWorkRes = {
  error?: Err
  success?: boolean
}

export const submitWork = async (payload: SubmitWorkPayload) => {
  const formData = new FormData()

  Object.entries(payload).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(v => formData.append(key, v))
      return
    }

    formData.append(key, value)
  })

  const res = await fetch(`${API_BASEPATH}/api/artworks/submit-work`, {
    method: 'POST',
    body: formData,
  })
  const data = await res.json()
  return data as SubmitWorkRes
}
