import { useQuery } from 'react-query'
import { getCookieValue } from 'utils/cookies'

const initialData = { clientId: null, sessionId: null }

function fetchClientId() {
  const cookie = getCookieValue(document.cookie, '_ga')
  if (!cookie) return null

  return cookie.substring(6)
}

function fetchSessionId() {
  const cookie = getCookieValue(document.cookie, '_ga_30YXXLWBKN')
  if (!cookie) return null

  const sessionId = cookie.substring(6).split('.').at(0)
  if (!sessionId) return null

  return sessionId
}

/**
 * Fetches the `clientId` and `sessionId` from the Google Analytics cookies.
 */
export function useAnalyticsVariables() {
  const { data = initialData } = useQuery({
    queryKey: ['analytics-variables'],
    queryFn: () => {
      const clientId = fetchClientId()
      const sessionId = fetchSessionId()

      if (!clientId || !sessionId) {
        throw new Error('Could not load variables')
        return initialData
      }

      return { clientId, sessionId }
    },
    initialData,
    refetchOnWindowFocus: false,
    retry: true,
  })

  return data
}
