export type Err = {
  name: string
  message: string
  status: number
  details?: {
    err?: string
  }
}

const apiDomain = process.env.NEXT_PUBLIC_API_DOMAIN || 'cms.preview.fairart.io'

// export const API_BASEPATH = `https://${apiDomain}`
export const API_BASEPATH = apiDomain.startsWith('http')
  ? apiDomain
  : `https://${apiDomain}`

/**
 * Determines the API basepath to be used, depending whether the function runs on the client or the server. For the server, uses the CMS. For the client, uses the frontend.
 */
export function getApiBasepath() {
  const apiBasepath =
    process.env.API_CMS_DOMAIN || process.env.NEXT_PUBLIC_API_DOMAIN

  if (apiBasepath?.startsWith('http')) return apiBasepath

  return `https://${apiBasepath}`
}
