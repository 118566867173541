import { IArtist } from '@/types'
import {
  FollowArtistPreferences,
  ImageSizes,
  SavedArtwork,
  StrapiPagination,
} from 'types'
import { API_BASEPATH, Err, getApiBasepath } from '.'
import qs from 'qs'
import { Artist, FollowArtist, mapIArtistToArtist } from 'utils/data'
import { useQuery } from 'react-query'

export const artistCardPopulation = {
  fields: ['name', 'dateOfBirth'],
  populate: {
    images: {
      fields: ['name', 'formats', 'url'],
    },
    nationalities: {
      fields: ['name', 'alpha2Code'],
    },
  },
}

type Response = {
  data: IArtist[]
  meta: {
    pagination: {
      page: number
      pageCount: number
      pageSize: number
      total: number
    }
  }
}

export type ArtistMediumCount = { label: string; count: number }

type GetArtistMediumsResponse = {
  mediums: ArtistMediumCount[]
}

type FollowArtistRes = {
  id: number
  error?: Err
}

type GetFollowedArtistsRes = {
  results: {
    id: number
    artworks: SavedArtwork[]
    artist: FollowArtist
  }[]
  pagination: StrapiPagination
}

type IsArtistFollowedRes = {
  error?: Err
  id?: number
}

export const getArtists = async (query?: string) => {
  const response = await fetch(`${API_BASEPATH}/api/artists?${query}`)
  const data = await response.json()
  return data as Response
}

export const getArtistsHeader = async () => {
  const artistIds =
    process.env.NEXT_PUBLIC_STAGE === 'production'
      ? [1289, 1229, 1358, 1373, 1429, 1337, 1241, 1438, 1392]
      : [421, 365, 487, 502, 561, 453, 381, 571, 534]

  const query = qs.stringify(
    {
      fields: ['name', 'dateOfBirth'],
      populate: {
        images: {
          fields: ['name', 'formats', 'url'],
        },
        nationalities: {
          fields: ['name', 'alpha2Code'],
        },
        artworks: {
          fields: ['id'],
        },
      },
      filters: {
        id: { $in: [...artistIds] },
      },
    },
    { encodeValuesOnly: true }
  )
  const { data: artists } = await getArtists(query)

  return artists?.map(artist => mapIArtistToArtist(artist)) || []
}

export const getArtist = async (id: number, query?: string) => {
  const response = await fetch(`${API_BASEPATH}/api/artists/${id}?${query}`)
  const data = await response.json()
  return data as { data: IArtist }
}

export const getArtistMediums = async (id: number) => {
  const response = await fetch(`${API_BASEPATH}/api/artists/mediums/${id}`)
  const data = await response.json()
  return data as GetArtistMediumsResponse
}

export const getTrendingArtists = async () => {
  const response = await fetch(
    `${getApiBasepath()}/api/artists/features/trending`
  )
  const data = await response.json()
  return data as {
    id: number
    dateOfBirth?: string
    name?: string
    images?: ImageSizes[]
  }[]
}

export const followArtist = async (
  payload: {
    artistId: number
  } & FollowArtistPreferences
) => {
  const res = await fetch(`${API_BASEPATH}/api/follow-artists`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
  const data = await res.json()
  return data as FollowArtistRes
}

export const updateFollowPreferences = async (
  followId: number,
  payload: FollowArtistPreferences
) => {
  const res = await fetch(`${API_BASEPATH}/api/follow-artists/${followId}`, {
    method: 'PUT',
    body: JSON.stringify({ data: payload }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
  return await res.json()
}

export const getFollowedArtists = async (
  payload: {
    name?: string
    pageSize?: string
    pageNumber?: string
  } = {}
) => {
  const query = new URLSearchParams(payload).toString()

  const res = await fetch(`${API_BASEPATH}/api/follow-artists?${query}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
  const data = await res.json()
  return data as GetFollowedArtistsRes
}

export const unfollowArtist = async (id: number) => {
  const res = await fetch(`${API_BASEPATH}/api/follow-artists/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
  return (await res.json()) as { error?: Err; id?: null }
}

export const isArtistFollowed = async (artistId: number) => {
  const res = await fetch(`${API_BASEPATH}/api/artists/is-follow/${artistId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
  return (await res.json()) as IsArtistFollowedRes
}

export const getArtistsCount = async () => {
  const query = qs.stringify(
    {
      fields: ['name'],
      pagination: { page: 1, pageSize: 0 },
    },
    { encodeValuesOnly: true }
  )
  const {
    meta: {
      pagination: { total: totalArtists },
    },
  } = await getArtists(query)

  return totalArtists
}

export function useArtistsHeader(artists: Artist[] | null | undefined) {
  return useQuery({
    queryKey: ['artists-header'],
    queryFn: async () => {
      return await getArtistsHeader()
    },
    initialData: artists,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retry: false,
    staleTime: Infinity,
  })
}
