import { IUser } from '@/types'
import { ANALYTICS_EVENTS, useSendAnalyticsEvent } from 'api/analytics'
import {
  ConfirmEmailCredentials,
  ForgotPasswordCredentials,
  LoginCredentials,
  RegisterCredentials,
  ResetPasswordCredentials,
  SendConfirmationEmailCredentials,
  VerifyResetPasswordTokenCredentials,
  confirmEmail as confirmEmailHandler,
  forgotPassword as forgotPasswordHandler,
  login as loginHandler,
  register as registerHandler,
  resetPassword as resetPasswordHandler,
  sendConfirmationEmail as sendConfirmationEmailHandler,
  verifyResetPasswordToken as verifyResetPasswordTokenHandler,
} from 'api/auth'
import { saveLastActive } from 'api/profiles'
import { getCurrentSocialUser, getCurrentUser, userErrors } from 'api/users'
import { ToastSuccessIcon } from 'components/common/Toast'
import routes from 'constants/routes'
import dayjs from 'dayjs'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { createContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { titleCase } from 'utils/strings'

type Props = {
  children: React.ReactNode
}

type Callbacks = {
  onAuthSuccess: () => void
}

type State = {
  isLoading: boolean
  isAuthenticated: boolean
  user?: IUser
  error?:
    | Error
    | {
        0: {
          messages: {
            0: {
              message: string
            }
          }
        }
      }
  setError: React.Dispatch<React.SetStateAction<Error | undefined>>
  login: (credentials: LoginCredentials) => Promise<Error | undefined>
  fetchUser: () => Promise<Error | undefined>
  logout: () => Promise<void>
  register: (credentials: RegisterCredentials) => Promise<Error | undefined>
  confirmEmail: (
    credentials: ConfirmEmailCredentials
  ) => Promise<{ user?: IUser; error?: Error }>
  sendConfirmationEmail: (
    credentials: SendConfirmationEmailCredentials
  ) => Promise<Error | undefined>
  forgotPassword: (
    credentials: ForgotPasswordCredentials
  ) => Promise<Error | undefined>
  resetPassword: (
    credentials: ResetPasswordCredentials
  ) => Promise<Error | undefined>
  verifyResetPasswordToken: (
    credentials: VerifyResetPasswordTokenCredentials
  ) => Promise<Error | undefined>
  setCallbacks: React.Dispatch<React.SetStateAction<Callbacks | undefined>>
}

export const AuthContext = createContext<State>({} as State)

export const AuthProvider = ({ children }: Props) => {
  const { t } = useTranslation()
  const router = useRouter()
  const [user, setUser] = useState<IUser>()
  const [error, setError] = useState<Error>()
  const [isLoading, setIsLoading] = useState(true)
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const [callbacks, setCallbacks] = useState<Callbacks>()

  const { sendAnalyticsEvent } = useSendAnalyticsEvent()

  function sendAnalyticsEvents(_user: IUser | undefined, provider: string) {
    const _provider = titleCase(provider)

    window?.dataLayer?.push({
      event: 'sign_up',
      payload: {
        Email: _user?.email,
        Username: _user?.username,
        signed_in: _provider,
      },
    })

    if (_user?.email) {
      sendAnalyticsEvent({
        eventName: ANALYTICS_EVENTS.SIGN_UP,
        email: _user.email,
        provider: _provider,
        userName: _user.username as string,
      })
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      callbacks?.onAuthSuccess()

      handleSaveLastActive().catch()

      window.addEventListener('beforeunload', handleSaveLastActive)
    }

    return () => {
      window.removeEventListener('beforeunload', handleSaveLastActive)
    }
  }, [callbacks, isAuthenticated])

  useEffect(() => {
    const load = () => {
      const {
        access_token,
        provider = 'google',
        firstName = '',
        lastName = '',
      } = router.query
      const storedToken = localStorage.getItem('token')

      // If there's an access_token the user used social login (google/apple)
      if (
        access_token &&
        typeof access_token === 'string' &&
        (provider === 'google' || provider === 'apple')
      ) {
        const fetchCurrentUser = async () => {
          const { error, jwt, user } = await getCurrentSocialUser(
            access_token,
            provider,
            firstName as string,
            lastName as string
          )

          if (error || !jwt || !user) {
            if (error) {
              const err = error.details?.err
              const fairartUserExists = err === userErrors.emailAlreadyTaken
              const fairartUserExistsNotGoogle =
                err === userErrors.emailAlreadyTakenNotGoogle
              const fairartUserExistsNotApple =
                err === userErrors.emailAlreadyTakenNotApple

              if (
                fairartUserExists ||
                fairartUserExistsNotApple ||
                fairartUserExistsNotGoogle
              ) {
                router.replace(
                  {
                    pathname: routes.homepage,
                    query: {
                      fairartUserExists,
                      fairartUserExistsNotApple,
                      fairartUserExistsNotGoogle,
                    },
                  },
                  undefined
                )
              }

              setError(error)
            } else {
              setError(new Error('No token provided'))
            }

            await logout()
            return
          }

          const currentDate = dayjs()
          const userCreatedDate = dayjs(user.createdAt)
          const minutesDiff = currentDate.diff(userCreatedDate, 'minutes')

          // here we can guess the user signed up
          if (minutesDiff < 1) {
            toast.success(t('common:account_created') as string, {
              icon: <ToastSuccessIcon />,
            })
            sendAnalyticsEvents(user, provider)
          }

          identify(user?.username, user?.email)

          localStorage.setItem('token', jwt)
          localStorage.setItem('user', JSON.stringify(user))

          setIsAuthenticated(true)
          setUser(user)
          setIsLoading(false)

          const redirectTo = localStorage.getItem('redirectTo')

          if (redirectTo && typeof redirectTo === 'string') {
            router.replace(redirectTo)
            localStorage.removeItem('redirectTo')
          } else {
            router.replace(router.pathname)
          }
        }

        fetchCurrentUser()
      } else if (storedToken) {
        // Test the current stored token
        const fetchCurrentUser = async () => {
          const { email, username, error } = await getCurrentUser()

          if (error) {
            setError(error)
            await logout()
            return
          }

          identify(username, email)

          const storedUser = localStorage.getItem('user')
          if (storedUser) {
            setIsAuthenticated(true)
            setUser(JSON.parse(storedUser))
          }

          setIsLoading(false)
        }

        fetchCurrentUser()
      } else {
        setIsAuthenticated(false)
        setIsLoading(false)
      }
    }

    load()

    // Listen for window logout event and logout the user when the event is emmited
    window.addEventListener('logout', logout)

    return () => {
      window.removeEventListener('logout', logout)
    }
  }, [
    router.query.access_token,
    router.query.provider,
    router.query.firstName,
    router.query.lastName,
  ])

  useEffect(() => {
    setError(undefined)
  }, [router.asPath])

  const identify = (name?: string, email?: string) => {
    if (typeof __ls === 'function') {
      __ls('identify', {
        name,
        email,
      })
    }
  }

  const handleSaveLastActive = async () => {
    if (localStorage.getItem('token')) {
      await saveLastActive()
    }
  }

  const login = async ({ email, password }: LoginCredentials) => {
    setIsLoading(true)

    const { jwt, user, error, message } = await loginHandler({
      email,
      password,
    })

    const messageText = message?.[0]?.messages

    if (error || messageText || !jwt || !user) {
      const errorMessages: any = error || messageText

      setError(errorMessages)
      setIsLoading(false)
      return errorMessages
    }

    localStorage.setItem('token', jwt)
    localStorage.setItem('user', JSON.stringify(user))

    setUser(user)
    setError(undefined)
    setIsAuthenticated(true)
    setIsLoading(false)
  }

  const logout = async () => {
    await handleSaveLastActive()

    setIsLoading(true)

    localStorage.removeItem('token')
    localStorage.removeItem('user')
    localStorage.removeItem('redirectTo')

    setUser(undefined)
    setIsAuthenticated(false)
    setIsLoading(false)
  }

  const register = async ({
    firstName,
    lastName,
    username,
    email,
    password,
  }: RegisterCredentials) => {
    setIsLoading(true)
    setError(undefined)

    const { user, error } = await registerHandler({
      firstName,
      lastName,
      username,
      email,
      password,
    })

    if (error || !user) {
      setError(error)
      setIsLoading(false)
      return error
    }

    localStorage.setItem('user', JSON.stringify(user))

    setUser(user)
    setError(undefined)
    setIsAuthenticated(false)
    setIsLoading(false)
  }

  const confirmEmail = async ({
    confirmationToken,
  }: ConfirmEmailCredentials) => {
    setIsLoading(true)

    const { jwt, user, error } = await confirmEmailHandler({
      confirmationToken,
    })

    if (error || !jwt || !user) {
      setError(error)
      setIsLoading(false)
      return { user: undefined, error }
    }

    localStorage.setItem('token', jwt)
    localStorage.setItem('user', JSON.stringify(user))

    setUser(user)
    setError(undefined)
    setIsAuthenticated(true)
    setIsLoading(false)

    return { user, error: undefined }
  }

  const sendConfirmationEmail = async ({
    email,
  }: SendConfirmationEmailCredentials) => {
    setIsLoading(true)
    const { sent, error } = await sendConfirmationEmailHandler({
      email,
    })

    if (error || !sent) {
      setError(error)
      setIsLoading(false)
      return error
    }

    setError(undefined)
    setIsAuthenticated(false)
    setIsLoading(false)
  }

  const forgotPassword = async ({ email }: ForgotPasswordCredentials) => {
    setIsLoading(true)
    const { error } = await forgotPasswordHandler({ email })

    if (error) {
      setError(error)
      setIsLoading(false)
      return error
    }

    setIsLoading(false)
    setUser(undefined)
    setIsAuthenticated(false)
    setIsLoading(false)
  }

  const resetPassword = async ({
    password,
    passwordConfirmation,
    verificationToken,
  }: ResetPasswordCredentials) => {
    setIsLoading(true)

    const { error } = await resetPasswordHandler({
      password,
      passwordConfirmation,
      verificationToken,
    })

    if (error) {
      setError(error)
      setIsLoading(false)
      return error
    }

    setIsLoading(false)
    setUser(undefined)
    setIsAuthenticated(false)
    setIsLoading(false)
  }

  const verifyResetPasswordToken = async ({
    verificationToken,
  }: VerifyResetPasswordTokenCredentials) => {
    setIsLoading(true)

    const { error } = await verifyResetPasswordTokenHandler({
      verificationToken,
    })

    if (error) {
      setError(error)
      setIsLoading(false)
      return error
    }

    setIsLoading(false)
    setUser(undefined)
    setIsAuthenticated(false)
    setIsLoading(false)
  }

  const fetchUser = async () => {
    setIsLoading(true)
    const user = await getCurrentUser()

    if (user && user?.error) {
      setError(error)
      setIsLoading(false)
      return error
    }

    localStorage.setItem('user', JSON.stringify(user))

    setUser(user)
    setError(undefined)
    setIsLoading(false)
  }

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        isAuthenticated,
        user,
        error,
        setError,
        login,
        logout,
        register,
        confirmEmail,
        sendConfirmationEmail,
        forgotPassword,
        resetPassword,
        verifyResetPasswordToken,
        fetchUser,
        setCallbacks,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
