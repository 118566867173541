import { API_BASEPATH } from 'api'
import { useAnalyticsVariables } from 'hooks/useAnalyticsVariables'
import { useRef } from 'react'
import { useMutation } from 'react-query'

export const ANALYTICS_EVENTS = {
  PURCHASE: 'purchase_1',
  SALE: 'sale_1',
  ADD_TO_CART: 'add_to_cart_1',
  BEGIN_CHECKOUT: 'begin_checkout_1',
  BID: 'bid_1',
  LIST: 'list_1',
  SIGN_UP: 'sign_up_1',
  VIEW_ARTIST: 'view_artist_1',
  VIEW_ARTWORK: 'view_artwork_1',
} as const

interface PurchaseEventPayload {
  eventName: typeof ANALYTICS_EVENTS.PURCHASE
  orderId: string
}

interface SaleEventPayload {
  eventName: typeof ANALYTICS_EVENTS.SALE
  orderId: string
}

interface AddToCartEventPayload {
  eventName: typeof ANALYTICS_EVENTS.ADD_TO_CART
  bidId: string
}

interface BeginCheckoutEventPayload {
  eventName: typeof ANALYTICS_EVENTS.BEGIN_CHECKOUT
  orderId: string
}

interface OfferEventPayload {
  eventName: typeof ANALYTICS_EVENTS.BID
  bidId: string
}

interface ListEventPayload {
  eventName: typeof ANALYTICS_EVENTS.LIST
  askId: string
}

interface SignUpEventPayload {
  eventName: typeof ANALYTICS_EVENTS.SIGN_UP
  email: string
  userName: string
  provider: string | null
}

interface ViewArtistEventPayload {
  eventName: typeof ANALYTICS_EVENTS.VIEW_ARTIST
  artistId: string
  artistName: string
}

interface ViewArtworkEventPayload {
  eventName: typeof ANALYTICS_EVENTS.VIEW_ARTWORK
  artworkId: string
  artworkName: string
}

type SendAnalyticsEventPayload =
  | PurchaseEventPayload
  | SaleEventPayload
  | AddToCartEventPayload
  | BeginCheckoutEventPayload
  | OfferEventPayload
  | ListEventPayload
  | SignUpEventPayload
  | ViewArtistEventPayload
  | ViewArtworkEventPayload

interface AnalyticsVariables {
  clientId: string
  sessionId: string
}

export async function sendAnalyticsEvent(
  payload: SendAnalyticsEventPayload & AnalyticsVariables
) {
  const token = localStorage.getItem('token')
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
  }

  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  const res = await fetch(
    `${API_BASEPATH}/api/profiles/produce/google-analytics`,
    {
      method: 'POST',
      body: JSON.stringify(payload),
      headers,
    }
  )

  const data = await res.json()

  return data
}

export function useSendAnalyticsEvent() {
  const { clientId, sessionId } = useAnalyticsVariables()
  const wasFired = useRef(false)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { mutate, mutateAsync, ...rest } = useMutation({
    mutationFn: async (payload: SendAnalyticsEventPayload) => {
      if (!clientId || !sessionId) {
        console.error('Analyics variables are missing.')
        return
      }

      // console.log(`### SENDING ${payload.eventName} ANALYTICS EVENT`)

      return sendAnalyticsEvent({ ...payload, clientId, sessionId })
    },
    onMutate() {
      wasFired.current = true
    },
  })

  return {
    sendAnalyticsEvent: mutateAsync,
    wasFired: wasFired.current,
    ...rest,
  }
}
